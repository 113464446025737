<template>
    <div>
      <liste
        label-bouton-ajout-element="Export Excel"
        :nom-page="nomPage"
        key-name="idUtilisateur"
        default-sort-by="matricule"
        :items-table="listeUtilisateursParEntreprise"
        :loading="loading"
        :titres-table="headers"
        :mobile-breakpoint="740"
        @ajout-element="ouvrirExportPointage"
      >
        <template #titre>
              <span
                v-if="configuration.entrepriseSelectionnee != null"
              >
                {{`${configuration.entrepriseSelectionnee.nomEntreprise}`}}
              </span>
        </template>

        <template #selectAll>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="selectionGlobale()"
              >
                <v-icon>mdi-checkbox-multiple-marked</v-icon>
              </v-btn>
            </template>
            <span>Tout selectionner / deselectionner</span>
          </v-tooltip>
        </template>

        <template #nombreRessourcesSelect>
          <h6>{{selectRessources.length}} ressources selectionnées</h6>
        </template>

        <template #choisirDate>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Date"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>      
            <v-date-picker
              v-model="date"
              type="month"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </template>

        <template #ressources="actionsSlotProps">
          <v-checkbox
            v-model="selectRessources"      
            :value="actionsSlotProps.item.ressourceEntreprise"
          >
          </v-checkbox>
        </template>

        <template #matricules="actionsSlotProps">
          <v-edit-dialog
            :return-value.sync="actionsSlotProps.item.matricule"
            large
            :saving="saving"
            @open="open((actionsSlotProps.item))"
            @save="save"
            @cancel="clearData"
          >
            <div>{{ actionsSlotProps.item.matricule }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h8">
                Update matricule de {{utilisateurAModifier.prenom +" "+ utilisateurAModifier.nom }}
              </div>
              <v-text-field
                v-model="utilisateurAModifier.matricule"
                :rules="[rules.matricule]"
                maxlength="8"
                single-line
                counter
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </liste>
    </div>
  </template>
  <script>
  import Liste from "@/views/ListeExport";
  import { mapActions, mapGetters, mapState } from "vuex";
  import { EventHub } from "@/event-hub.js";
  import RULES from "@/utils/enums";
  import {heureFormaterToMinutes } from "@/utils/transform";
 
  export default {
    components: {
      Liste,
    },
    data: () => ({
      loading: true,
      selectRessources:[],
      nomPage: "ressource",
      rules: RULES,
      saving: false,
      utilisateurAModifier: {},
      date: new Date().toISOString().slice(0, 10),
      menu: false,
      modal: false,
      today:"",
      datefin:"",

    }),
    computed: {
      ...mapState("entreprise", ["utilisateurs", "entreprises", "roles", 
      "configuration", "entrepriseSelectionnee", "utilisateursByEntrepriseSelectionnee"]),
      ...mapState("utilisateur", ["utilisateur"]),
      ...mapGetters("entreprise", [
        "getNomEntrepriseByidRessource",
        "getActifByidRessource", "entreprises" ]),
      ...mapGetters("utilisateur", ["nomRole"]),

      headers: function() {
        // not using cellClass in headers has it doesn't work for mobile in Vuetify 2.4.0
        let headers = [
          { text: "Ressource", value:"ressourceEntreprise", align: "start", width: "150px"},
          { text: "Matricule", value:"matricule", align: "start", width: "150px"},
          { text: "Nom", value: "nom", align: "start", width: "150px"},
          { text: "Prénom", value: "prenom", width: "150px"},
          { text: "E-mail", value: "mail"},
          { text: "Rôle", value: "role", sortable: true, align: "center", width: "150px"},
        ];
        return headers;
      }, // fin header

      listeUtilisateursParEntreprise: function () {
        const listUser = Object.values(
          this.configuration.utilisateursByEntrepriseSelectionnee).map(
          (utilisateursByEntrepriseSelectionnee) => ({
          ...utilisateursByEntrepriseSelectionnee,
          ressourceEntreprise: utilisateursByEntrepriseSelectionnee.ressources[0],
          role: this.roles[utilisateursByEntrepriseSelectionnee.idRole].nomRole,
          }),
          );
        let listeUtilisateursParEntreprise = listUser;        
        return listeUtilisateursParEntreprise;
      }, // fin listeUtilisateursParEntreprise
    }, //fin computed

    watch: {
      //
    },
    created() {
      this.recupererUtilisateurs()
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
    mounted() {
      //
    },
    
    methods: {
      ...mapActions("entreprise", ["recupererUtilisateurs", "updateUtilisateur", "setUtilisateursByEntrepriseSelectionnee"]),
      ...mapActions("utilisateur", ["setRessourcesSelectionnee"]),
      heureFormaterToMinutes: heureFormaterToMinutes,

      //edit matricule
      open (Utilisateurs) {
        this.utilisateurAModifier = {
          idUtilisateur: Utilisateurs.idUtilisateur,
          prenom: Utilisateurs.prenom,
          matricule: Utilisateurs.matricule,
          nom: Utilisateurs.nom,
        };
      }, //fin open
      save () {
        this.saving = true;
        this.updateUtilisateur(this.utilisateurAModifier)
          .then(() => {
            this.$emit("fermer");
            this.clearData();          
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.saving = false;
            this.setUtilisateursByEntrepriseSelectionnee();
          });
      }, //fin save
      clearData () {
        this.utilisateurAModifier = {};
      }, //fin clear
      //fin edit matricule

     selectionGlobale () {
        if(!Array.isArray(this.selectRessources)){
          this.selectRessources = [];  
        };
        if (!this.selectRessources[0]) {
          for (let index = 0; index < this.listeUtilisateursParEntreprise.length; index++) {
            this.selectRessources.push(this.listeUtilisateursParEntreprise[index].ressourceEntreprise);        
          }  
        } else {
          this.selectRessources = [];    
        }
      }, // fin selectionGlobale

      ouvrirExportPointage() {
        this.today = new Date(this.date); //date du jour
        this.dateFin = new Date(this.today.getFullYear(), this.today.getMonth()+1, 1).toISOString().slice(0, 10);
        EventHub.$emit("AFFICHER_FORMULAIRE", {
          type: "export-pointages-excel",
          id: this.selectRessources,
          data: {
            date: this.dateFin,
            listeIdRessources: this.selectRessources,
            entreprise: this.configuration.entrepriseSelectionnee.idEntreprise,
            title: this.configuration.entrepriseSelectionnee.nomEntreprise +" "+ this.date,
          },
        });
      }, //fin ouvrirExportPointage
    }, // fin methods
  };
  </script>
  <style>
  .role {
    padding: 2px 4px 2px 4px;
    border-radius: 20px;
    color: white;
  }
  
  /* Gestion affichage mobile des chips */
  .v-data-table__mobile-row__cell .role {
    padding: 4px 16px 4px 16px;
  }
  </style>
  